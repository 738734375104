.App {
  text-align: center;
  color: white;
}

/* 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  height:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: white;
}

@media only screen and (min-width: 1081px) {
  .main-header{
    font-size: 10vh;
  }
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.container-class {
  background: darkslategray
}
.white-bg {
  background: white
}
.main_header {
  font-family: 'Comforter Brush', cursive;
  font-size: 10vw;
  font-weight: 900;
  color: orangered;
}
.sub_header {
  font-weight: bold;
  text-decoration: underline;
}
#nav-bar_ribbon {
  background: orangered;
  min-height: 66px;
}

.main_search-icon {
  margin:10px;
  font-size: 25px;
  color: black;
}

.main_row:nth-child(1) {
  border: solid 2px white;
}
.main_row {
  border: solid 2px white;
  border-top: none;
}

#main_table {
  margin: 0 16px;
}

.article-container {
  margin: auto;
  max-width: 800px;
}

.bold {
  font-weight: bold;
}

.coral {
  color: coral
}

.orangered {
  color: orangered;
}

.tyrant-name {
  font-size: 18px;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}
.black-bg {
  background-color: black;
}
.auth-button {
  color: black;
  border: solid black 1px;
  border-radius: 5px;
  background: beige;
  padding: 5px 10px;
}

.overflowX {
  overflow: hidden;
  width: 100%;
}

.tell-your-story {
  width: 208px;
  color: black;
  border: solid black 1px;
  border-radius: 5px;
  background: beige;
}

.nav-link {
  color: black;
  font-weight: bold;
  font-size: 16px;
}
.mobile-link {
  color: black;
  font-weight: bold;
  font-size: 22px;
}

.origin-story {
  padding: 20px;
}

#main_search-box{
  padding: 5px;
  background: whitesmoke;
}

.footer-item{
  cursor: pointer;
}

.paragraph{
  white-space: pre-wrap;
}

.back-button{
  text-align: left !important;
  padding: 5px;
  font-size: 20px !important;
}

.submit-button-container {
  display:flex;
  justify-content:flex-end;
}

.item-link {
  cursor: pointer;
}

.row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.admin-submit {
  margin-top: 30px;
}

.admin-subject-change-btn{
  cursor: pointer;
  color: coral;
  text-decoration: underline;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sort-dir-btn{
  width: 22px;
  height: 22px;
  background-color: black;
  color: white;
  
}

.sort-dir-icon{
  border: solid black 1px;
  margin: -12px -7px;
  border-radius: 4px;
  width: 22px;
  height: 22px;
  background: white;
  color: black;
}

.black{
  background: black;
  padding: 4px 6px;
}

.filter-sort-style {
  width: 100%;
  margin: 0 15px;
  padding-right: 30px;
}

.sort-label {
  padding: 0 7px;
}

.img-container {
  width: 100%;
  margin: auto;
}
.gray-box{
  display: flex;
  margin:auto;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  background: lightgray;
}